import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WelcomeComponent} from "./components/welcome/welcome.component";
import {EditorComponent} from "./components/editor/editor.component";
import {EnergyPricesComponent} from "./components/editor/editor-tabs/energy-prices/energy-prices.component";
import {PhotovoltaicsComponent} from "./components/editor/editor-tabs/photovoltaics/photovoltaics.component";
import {WelcomeGuard} from "./guards/welcome.guard";
import {CsrfAuthGuard} from "./guards/csrf-auth.guard";
import {NoPermissionsComponent} from "./components/no-permissions/no-permissions.component";
import {EditorTabsComponent} from "./components/editor/editor-tabs/editor-tabs.component";
import {EditorGuard} from "./guards/editor.guard";

const routes: Routes = [
  {path: 'welcome', component: WelcomeComponent, canActivate: [WelcomeGuard]},
  {
    path: 'editor', component: EditorComponent, canActivate: [CsrfAuthGuard, EditorGuard], children: [
      {
        path: ':countryCode', component: EditorTabsComponent, children: [
          {path: 'energy-prices', component: EnergyPricesComponent},
          {path: 'photovoltaics', component: PhotovoltaicsComponent},
          {path: '**', redirectTo: 'energy-prices'}
        ]
      }
    ]
  },
  {path: 'no-permissions', component: NoPermissionsComponent, canActivate: [CsrfAuthGuard]},
  {path: '**', redirectTo: '/welcome'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
