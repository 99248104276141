import {Injectable} from "@angular/core";
import {BehaviorSubject, lastValueFrom, map, Observable, of, switchMap, tap} from "rxjs";
import {PermissionsService} from "./permissions.service";

const NONE = ''

@Injectable({
  providedIn: "root"
})
export class CountryService {
  private selected = new BehaviorSubject<string>(NONE)
  selected$ = this.selected.asObservable()

  private loading = new BehaviorSubject(false);
  loading$ = this.loading.asObservable()

  private readonly _available$: Observable<string[]>
  get available$(): Observable<string[]> {
    return of(null).pipe(
      tap(() => this.loading.next(true)),
      switchMap(() => this._available$),
      tap(() => this.loading.next(false))
    );
  }

  constructor(private permissions: PermissionsService) {
    this._available$ = permissions.countryCodes$.pipe(
      map(countryCodes => countryCodes || [])
    )
  }

  selectCountry(newCountry: string): Promise<string> {
    return lastValueFrom(this._selectCountry(newCountry))
  }

  private _selectCountry(newCountry: string): Observable<string> {
    if (newCountry && this.selected.value == newCountry) {
      // country is selected already
      return of(this.selected.value)
    }

    return this._available$.pipe(
      map(available => {
        if (available.length === 0) {
          // no available countries
          this.selected.next(NONE)
        } else if (!newCountry || !available.includes(newCountry)) {
          // null selected or selected countryCode is not available
          this.selected.next(available[0])
        } else {
          // valid selection was made
          this.selected.next(newCountry)
        }

        return this.selected.value
      })
    )
  }
}
