import {Component, OnDestroy, OnInit} from '@angular/core';
import {CountryService} from "../../../services/country.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-editor-tabs',
  templateUrl: './editor-tabs.component.html',
  styleUrls: ['./editor-tabs.component.scss']
})
export class EditorTabsComponent implements OnInit, OnDestroy {
  links = [
    {label: 'Energy Prices', routerLink: 'energy-prices'},
    {label: 'Photovoltaics', routerLink: 'photovoltaics'},
  ];

  activeLink?: { label: string, routerLink: string }

  private selectedCountrySub!: Subscription

  constructor(private country: CountryService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    let activeRouterLink = this.activatedRoute.firstChild!.snapshot.url[0].path
    this.activeLink = this.links.find(o => o.routerLink === activeRouterLink)
    // handles changes of selected country code
    this.selectedCountrySub = this.country.selected$.subscribe(selected => {
      if (selected && this.activeLink) {
        this.router.navigate(['editor', selected, this.activeLink.routerLink]).then()
      }
    })
  }

  ngOnDestroy(): void {
    this.selectedCountrySub.unsubscribe()
  }
}
