import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GuardService {
  private deciding = new BehaviorSubject<boolean>(true)
  deciding$ = this.deciding.asObservable()

  private latestError = new BehaviorSubject<string | null>(null)
  latestError$ = this.latestError.asObservable()

  constructor() {
  }

  startDeciding() {
    this.deciding.next(true)
  }

  error(error: string | null) {
    this.latestError.next(error)
  }

  finishDeciding() {
    this.deciding.next(false)
  }
}
