import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {GuardService} from "../../services/guard.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-no-permissions',
  templateUrl: './no-permissions.component.html',
  styleUrls: ['./no-permissions.component.scss']
})
export class NoPermissionsComponent {
  constructor(
    private matSnackBar: MatSnackBar,
    private guard: GuardService,
    private router: Router) {
  }

  reload() {
    this.matSnackBar.dismiss()
    this.guard.error(null) // clear error
    this.router.navigate(['editor']).then()
  }
}
