import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {catchError, first, Observable, switchMap} from "rxjs";
import {JwtUtil} from "../util/jwt-util";

@Injectable({
  providedIn: 'root'
})
export class CsrfTokenInterceptor implements HttpInterceptor {
  private AUTH_URL_ENDING = '/saml/csrf'

  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const withCredentials = req.url.endsWith(this.AUTH_URL_ENDING)
    req = req.clone({withCredentials: withCredentials})

    if (withCredentials) {
      return next.handle(req)
    }

    return this.auth.csrfToken$.pipe(
      first(val => val !== undefined),
      switchMap(token => {
        req = req.clone({setHeaders: {'Authorization': `CSRF ${token}`}})
        return next.handle(req).pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              if (JwtUtil.isCSRFTokenExpired(token!)) {
                return this.auth.fetchNewCsrfToken().pipe(
                  switchMap(() => this.intercept(req, next))
                )
              }
            }

            // unexpected error
            throw err
          })
        )
      })
    )
  }
}
