import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {FlexModule} from "@angular/flex-layout";
import {HeaderComponent} from './components/header/header.component';
import {GlobalHeaderModule} from "@vi/global-header";
import {environment} from 'src/environments/environment';
import {MatToolbarModule} from "@angular/material/toolbar";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {EditorComponent} from './components/editor/editor.component';
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {EnergyPricesComponent} from './components/editor/editor-tabs/energy-prices/energy-prices.component';
import {PhotovoltaicsComponent} from './components/editor/editor-tabs/photovoltaics/photovoltaics.component';
import {CsrfTokenInterceptor} from "./interceptors/csrf-token.interceptor";
import {CountrySelectorComponent} from './components/country-selector/country-selector.component';
import {MatLegacySnackBarModule as MatSnackBarModule} from "@angular/material/legacy-snack-bar";
import {MatDividerModule} from "@angular/material/divider";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {CsrfAuthGuard} from "./guards/csrf-auth.guard";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {FormsModule} from "@angular/forms";
import {WelcomeGuard} from "./guards/welcome.guard";
import { NoPermissionsComponent } from './components/no-permissions/no-permissions.component';
import { EditorTabsComponent } from './components/editor/editor-tabs/editor-tabs.component';
import {EditorGuard} from "./guards/editor.guard";


@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    HeaderComponent,
    EditorComponent,
    EnergyPricesComponent,
    PhotovoltaicsComponent,
    CountrySelectorComponent,
    NoPermissionsComponent,
    EditorTabsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexModule,
    HttpClientModule,
    GlobalHeaderModule.forRoot({
      baseUrl: environment.header.baseUrl,
      accountAppUrl: environment.header.accountAppUrl,
      logoutRedirectUrl: environment.applicationBaseUrl,
      appId: environment.auth.appId
    }),
    MatToolbarModule,
    MatTabsModule,
    MatSnackBarModule,
    MatDividerModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  providers: [
    WelcomeGuard,
    CsrfAuthGuard,
    EditorGuard,
    {provide: HTTP_INTERCEPTORS, useClass: CsrfTokenInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
