import { Component } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  environment = environment

  constructor(private auth:AuthService ) { }

  test() {
    let x = this.auth.hasValidCsrfToken()
    console.log(x)
  }
}
