import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, map, Observable, of, tap} from "rxjs";
import {environment} from "../../environments/environment";
import {Permissions} from "../model/permissions";
import {NotificationService} from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private readonly permissionsUrl = `${environment.backend.baseUrl}/availableCountries`

  countryCodes$: Observable<string[] | null>

  constructor(private http: HttpClient, private notification: NotificationService) {
    this.countryCodes$ = this.http.get<Permissions>(this.permissionsUrl).pipe(
      map((permissions) => permissions.countryCodes),
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 0: {
            notification.error('Could not reach backend to fetch permissions')
            break;
          }
          case 403: {
            notification.error('You do not have any permissions on this application')
            break;
          }
          default: {
            notification.error('Error while fetching permissions')
            break;
          }
        }
        return of(null);
      })
    )
  }
}
