<mat-toolbar>
  <mat-toolbar-row fxLayout="row">
    <div fxLayout="row" fxLayoutAlign="start center">
      <img class="logo" src="assets/images/vi-logo.svg" alt="Viessmann">
       <h1 class="title">Planning Tools Admin</h1>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
      <app-country-selector></app-country-selector>
      <vih-support-link></vih-support-link>
      <vih-app-switcher></vih-app-switcher>
      <vih-login [appManagesAuthentication]="true" (logoutEvent)="logout()"></vih-login>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
