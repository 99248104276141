import {Component, ViewEncapsulation} from '@angular/core';
import {EnergyPricesService} from "../../../../services/energy-prices.service";
import {first, Observable} from "rxjs";
import {EnergyPricesDataSource} from "./energy-prices.data-source";
import {NotificationService} from "../../../../services/notification.service";
import {Router} from "@angular/router";
import {CountryService} from "../../../../services/country.service";

@Component({
  selector: 'app-energy-prices',
  templateUrl: './energy-prices.component.html',
  styleUrls: ['./energy-prices.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnergyPricesComponent {
  displayedColumns = ['energyType', 'price', 'priceIncreaseRate', 'currency', 'unit', 'lastModifiedBy', 'lastModifiedDate']

  dataSource: EnergyPricesDataSource
  loading$: Observable<boolean>

  constructor(
    private country: CountryService,
    private router: Router,
    private energyPrices: EnergyPricesService,
    private notification: NotificationService) {
    this.dataSource = new EnergyPricesDataSource(energyPrices)
    this.loading$ = this.dataSource.loading$
  }

  submit() {
    const updates = this.dataSource.getChanges()

    if (updates.length === 0) {
      this.notification.info("Nothing to submit")
    }

    this.energyPrices.putEnergyPrices(updates)
      .pipe(first())
      .subscribe((energyPrices) => {
        this.dataSource.applyUpdates(energyPrices)
        this.notification.success(`Submitted ${energyPrices.length} energy prices`)
      })
  }

  test(){
    console.log(this.dataSource)
  }
}
