import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {finalize, map, Observable} from 'rxjs';
import {AuthService} from "../services/auth.service";
import {GuardService} from "../services/guard.service";

@Injectable({
  providedIn: 'root'
})
export class CsrfAuthGuard  {
  constructor(private guard: GuardService, private auth: AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.guard.startDeciding()

    if (this.auth.hasValidCsrfToken()) {
      this.guard.finishDeciding()
      return true
    }

    // start csrf token authentication process
    return this.auth.fetchNewCsrfToken().pipe(
      map((res) => {
        return !!res.token;
      }),
      finalize(() => this.guard.finishDeciding())
    )
  }

}
