import {Component} from '@angular/core';
import {delay, map, Observable} from "rxjs";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  authenticated$: Observable<boolean>;

  constructor(private auth: AuthService) {
    this.authenticated$ = this.auth.csrfToken$.pipe(
      delay(10), // to prevent ExpressionChangedAfterItHasBeenCheckedError
      map((token) => {
        return !!token
      })
    )
  }

  logout() {
    this.auth.onLogout()
  }
}
