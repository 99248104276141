import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) {
  }

  info(message: string) {
    this.snackBar.open(message, 'Dismiss', {
      duration: 3000,
      panelClass: ['info-snackbar']
    })
  }

  success(message: string) {
    this.snackBar.open(message, 'Dismiss', {
      duration: 3000,
      panelClass: ['success-snackbar']
    })
  }

  warn(message: string) {
    console.warn(message)
    this.snackBar.open(message, 'Dismiss', {
      duration: 5000,
      panelClass: ['warning-snackbar']
    })
  }

  error(message: string) {
    console.error(message)
    this.snackBar.open(message, 'Dismiss',{
      panelClass: ['error-snackbar']
    })
  }
}
