import {Environment} from "./environment.model";

export const environment: Environment = {
  production: false,

  //applicationBaseUrl: 'http://localhost:4200',
  applicationBaseUrl: 'https://planning-tools-admin-integration.viessmann.com',

  auth: {
    appId: 'planning-tools-admin',
    baseUrl: 'https://api-integration.viessmann.com',
    usersUrl: 'https://api-integration.viessmann.com/users/v1/users/me'
  },

  header: {
    baseUrl: 'https://api-integration.viessmann.com',
    accountAppUrl: 'https://account-integration.viessmann.com'
  },

  backend: {
    //baseUrl: "http://localhost:8080",
    baseUrl: "https://api-integration.viessmann.com/planning-core/planning-tools-admin",
  }
};
