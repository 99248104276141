<div *ngIf="loading$ | async" fxFill fxLayout="column" fxLayoutAlign="center center">
  <!-- Loading -->
  <mat-spinner></mat-spinner>
  <p>Loading energy-prices</p>
</div>

<div fxFlexFill fxLayout="column" [style.display]="(loading$ | async) ? 'none' : 'flex'">

  <!-- Finished loading -->
  <!-- Successfully loaded data from backend -->
  <mat-table fxFlex class="mat-elevation-z8" [dataSource]="dataSource">
    <!-- Energy Type -->
    <ng-container matColumnDef="energyType">
      <mat-header-cell *matHeaderCellDef>Energy Type</mat-header-cell>
      <mat-cell *matCellDef="let energyPrice">{{energyPrice.energyType}}</mat-cell>
    </ng-container>

    <!-- Price -->
    <ng-container matColumnDef="price">
      <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
      <mat-cell *matCellDef="let energyPrice">
        <div fxFlex class="input-container">
          <mat-form-field fxFlex appearance="outline">
            <input matInput type="number" step="any" [(ngModel)]="energyPrice.price">
            <button mat-icon-button matSuffix
                    *ngIf="dataSource.hasPriceChanged(energyPrice)"
                    matTooltip="Reset" (click)="dataSource.resetPrice(energyPrice)">
              <mat-icon>undo</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Price Increase Rate -->
    <ng-container matColumnDef="priceIncreaseRate">
      <mat-header-cell *matHeaderCellDef>Price Increase Rate</mat-header-cell>
      <mat-cell *matCellDef="let energyPrice">
        <div fxFlex class="input-container">
          <mat-form-field fxFlex appearance="outline">
            <input matInput type="number" step="any" [(ngModel)]="energyPrice.priceIncreaseRate">
            <button mat-icon-button matSuffix
                    *ngIf="dataSource.hasPriceIncreaseRateChanged(energyPrice)"
                    matTooltip="Reset" (click)="dataSource.resetPriceIncreaseRate(energyPrice)">
              <mat-icon>undo</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Currency -->
    <ng-container matColumnDef="currency">
      <mat-header-cell *matHeaderCellDef>Currency</mat-header-cell>
      <mat-cell *matCellDef="let energyPrice">{{energyPrice.currency}}</mat-cell>
    </ng-container>

    <!-- Unit -->
    <ng-container matColumnDef="unit">
      <mat-header-cell *matHeaderCellDef>Unit</mat-header-cell>
      <mat-cell *matCellDef="let energyPrice">{{energyPrice.unit}}</mat-cell>
    </ng-container>

    <!-- LastModifiedBy -->
    <ng-container matColumnDef="lastModifiedBy">
      <mat-header-cell *matHeaderCellDef>Modified By</mat-header-cell>
      <mat-cell *matCellDef="let energyPrice">{{energyPrice.lastModifiedBy}}</mat-cell>
    </ng-container>

    <!-- lastModifiedDate -->
    <ng-container matColumnDef="lastModifiedDate">
      <mat-header-cell *matHeaderCellDef>Modified Date</mat-header-cell>
      <mat-cell *matCellDef="let energyPrice">{{energyPrice.lastModifiedDate|date:'medium'}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <button fxFlex="0 1 auto" mat-raised-button class="submit-button" color="primary" (click)="submit()">Submit</button>
</div>
