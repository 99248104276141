import {Component, OnInit} from '@angular/core';
import {AuthService} from "./services/auth.service";
import {Observable} from "rxjs";
import {GuardService} from "./services/guard.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'planning-tools-admin-frontend';
  loading$: Observable<boolean>

  constructor(
    private auth: AuthService,
    private guard: GuardService) {
    this.loading$ = guard.deciding$
  }

  ngOnInit(): void {
    this.auth.init()
  }
}
