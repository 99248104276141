import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {finalize, map, Observable} from 'rxjs';
import {CountryService} from "../services/country.service";
import {GuardService} from "../services/guard.service";

@Injectable({
  providedIn: 'root'
})
export class EditorGuard  {
  constructor(private guard: GuardService, private country: CountryService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.guard.startDeciding()
    // urlSegments[0] = "editor"
    // urlSegments[1] = "<countryCode>"
    // urlSegments[2] = "editor-tab"
    let urlSegments = state.url.split('/').splice(1)
    let urlCountry = urlSegments[1]

    return this.country.available$.pipe(
      map(available => {
        let urlCountryUnavailable = !urlCountry || !available.includes(urlCountry)
        if (urlCountryUnavailable) {
          // no available country code is in url

          if (available.length > 0) {
            // if possible, select first available country
            this.country.selectCountry(available[0]).then()
            return this.router.createUrlTree(['editor', available[0]])
          } else {
            // otherwise clear country selection
            this.country.selectCountry('').then()
            return this.router.createUrlTree(['no-permissions'])
          }
        }

        this.country.selectCountry(urlCountry).then()
        return true
      }),
      finalize(() => this.guard.finishDeciding())
    )
  }

}
