<div fxLayout="column" fxFill>
  <nav mat-tab-nav-bar>
    <a mat-tab-link
       *ngFor="let link of links"
       [routerLink]="link.routerLink"
       routerLinkActive="" #rla="routerLinkActive"
       queryParamsHandling="merge"
       (click)="activeLink = link"
       [active]="rla.isActive">
      {{link.label | uppercase}}
    </a>
  </nav>
  <div fxFlex class="editor-content">
    <router-outlet></router-outlet>
  </div>
</div>
