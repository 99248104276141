import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {BehaviorSubject, catchError, finalize, forkJoin, Observable, of, switchMap} from "rxjs";
import {EnergyPrice} from "../model/energy-price";
import {environment} from "../../environments/environment";
import {CountryService} from "./country.service";
import {NotificationService} from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class EnergyPricesService {
  private readonly energyPricesUrl = `${environment.backend.baseUrl}/energy-prices`

  data$: Observable<EnergyPrice[] | null>

  private loadingSubject = new BehaviorSubject<boolean>(false)
  loading$ = this.loadingSubject.asObservable()

  constructor(
    private country: CountryService,
    private http: HttpClient,
    private notification: NotificationService
  ) {
    this.data$ = this.country.selected$.pipe(
      switchMap((countryCode) => {
        if (!countryCode) {
          // if no country code is selected
          return of(null)
        }

        const params = new HttpParams()
          .set('countryCode', countryCode)

        this.loadingSubject.next(true)

        // retrieve energy prices for selected country from backend
        return this.http.get<EnergyPrice[]>(this.energyPricesUrl, {params: params}).pipe(
          catchError((err: HttpErrorResponse) => {
            switch (err.status) {
              case 0: {
                this.notification.error('Could not reach backend to fetch energy-prices')
                break;
              }
              case 403: {
                this.notification.error('You are not authorized to query energy-prices for \'${countryCode}\'')
                break;
              }
              default: {
                this.notification.error('Error while fetching energy-prices')
                break;
              }
            }
            return of(null)
          }),
          finalize(() => this.loadingSubject.next(false))
        )
      })
    )
  }

  putEnergyPrices(energyPrices: EnergyPrice[]) {
    let updateObservables: Observable<EnergyPrice>[] = []
    energyPrices.forEach(energyPrice => {
      updateObservables.push(this.http.put<EnergyPrice>(this.energyPricesUrl, energyPrice))
    })
    return forkJoin<EnergyPrice[]>(updateObservables)
  }
}
