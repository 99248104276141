import {Component} from '@angular/core';
import {map, Observable} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {CountryService} from "../../services/country.service";

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent {
  countryCodes$: Observable<string[]>
  selectedCountryCode$: Observable<string | null>

  constructor(
    private country: CountryService,
    private notification: NotificationService) {
    this.countryCodes$ = country.available$.pipe(
      map((countryCodes) => countryCodes.sort())
    )

    this.selectedCountryCode$ = country.selected$
  }

  selectCountry(countryCode: string) {
    this.country.selectCountry(countryCode).then(selected => {
      this.notification.info(`Switched to country '${selected.toUpperCase()}'`)
    })
  }
}
