import jwt_decode from "jwt-decode";

export class JwtUtil {
  static isCSRFTokenExpired(token: string): boolean {
    const date = this.getTokenExpirationDate(token)
    if (!date) {
      return false
    }
    return !(date.valueOf() > new Date().valueOf())
  }

  private static getTokenExpirationDate(token: string): Date | null {
    const decoded = jwt_decode<{ exp: number }>(token)

    if (!decoded.exp) {
      return null
    }

    const date = new Date(0)
    date.setUTCSeconds(decoded.exp)
    return date
  }
}
