<div [ngSwitch]="(countryCodes$ | async)?.length">
  <!-- no available countries -->

  <button mat-icon-button *ngSwitchCase="0" class="country-selector" matTooltip="No countries available to you">
    <mat-icon >language</mat-icon>
  </button>

  <!-- one available country -->
  <button mat-button *ngSwitchCase="1" class="country-selector" matTooltip="No more countries available">{{selectedCountryCode$ | async | uppercase}}</button>

  <!-- at least two available countries -->
  <div *ngSwitchDefault>
    <button mat-button class="country-selector" [matMenuTriggerFor]="menu">{{selectedCountryCode$ | async | uppercase}}</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let countryCode of countryCodes$ | async"
              (click)="selectCountry(countryCode)">{{countryCode | uppercase}}</button>
    </mat-menu>
  </div>
</div>
