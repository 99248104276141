<div fxFill fxLayout="column">
  <app-header></app-header>
  <div *ngIf="loading$ | async; else content" fxFill fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
    <p>Checking permissions</p>
  </div>
  <ng-template #content>
    <div fxFlex fxFill>
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</div>
