import {BehaviorSubject, delay, Observable} from "rxjs";
import {EnergyPrice} from "../../../../model/energy-price";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {EnergyPricesService} from "../../../../services/energy-prices.service";

export class EnergyPricesDataSource implements DataSource<EnergyPrice> {
  private originalEnergyPrices: EnergyPrice[] = []

  private fetchEnergyPrices$: Observable<EnergyPrice[] | null>
  private energyPricesSubject = new BehaviorSubject<EnergyPrice[]>([])
  private energyPrices$ = this.energyPricesSubject.asObservable()

  loading$: Observable<boolean>

  constructor(private energyPrices: EnergyPricesService) {
    this.loading$ = this.energyPrices.loading$.pipe(delay(10))

    this.fetchEnergyPrices$ = this.energyPrices.data$
  }
  connect(collectionViewer: CollectionViewer): Observable<EnergyPrice[]> {
    this.fetchEnergyPrices$.subscribe((energyPrices) => {
      // when unable to fetch energy-prices, use empty array
      energyPrices = energyPrices || []

      // save deep-copy
      this.originalEnergyPrices = energyPrices.map(x => Object.assign({}, x))

      // sort and publish new energy-prices to ui
      const sorted = energyPrices.sort((ep1, ep2) => ep1.energyType.localeCompare(ep2.energyType))
      this.energyPricesSubject.next(sorted)
    })

    return this.energyPrices$
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.energyPricesSubject.complete()
  }

  hasPriceChanged(energyPrice: EnergyPrice) {
    return this.original(energyPrice).price !== energyPrice.price
  }

  hasPriceIncreaseRateChanged(energyPrice: EnergyPrice) {
    return this.original(energyPrice).priceIncreaseRate !== energyPrice.priceIncreaseRate
  }

  private getChange(current: EnergyPrice) {
    const original = this.original(current)
    if (current.price !== original.price
      || current.priceIncreaseRate !== original.priceIncreaseRate) {
      return current
    }

    return null
  }

  getChanges() {
    const changes: EnergyPrice[] = []

    const energyPrices = this.energyPricesSubject.value
    energyPrices.forEach(energyPrice => {
      const change = this.getChange(energyPrice)
      if (change) {
        // add to changes
        changes.push(change)
      }
    })

    return changes
  }

  applyUpdates(updates: EnergyPrice[]) {
    // update local copy of energy-prices with updates
    updates.forEach(update => {
      const original = this.originalEnergyPrices.find(original => original.id === update.id)!
      Object.assign(original, update)
    })
  }

  resetPrice(energyPrice: EnergyPrice) {
    const original = this.original(energyPrice)
    energyPrice.price = original.price
  }

  resetPriceIncreaseRate(energyPrice: EnergyPrice) {
    const original = this.original(energyPrice)
    energyPrice.priceIncreaseRate = original.priceIncreaseRate
  }

  private original(energyPrice: EnergyPrice) {
    return this.originalEnergyPrices.find(original => original.id === energyPrice.id)!
  }
}


