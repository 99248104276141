import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photovoltaics',
  templateUrl: './photovoltaics.component.html',
  styleUrls: ['./photovoltaics.component.scss']
})
export class PhotovoltaicsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log('photovoltaics initialized')
  }

}
