import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, share, Subscription, tap} from "rxjs";
import {HttpBackend, HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {JwtUtil} from "../util/jwt-util";
import {StorageKey} from "./storage-key";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public csrfToken$ = new BehaviorSubject<string | undefined>(undefined);
  private fetchNewCsrfToken$!: Observable<{ token: string }>;
  private fetchNewCsrfTokenSub: Subscription | undefined

  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public hasValidCsrfToken(): boolean {
    const token = this.csrfToken$.value;
    return !!token && this.isCsrfTokenValid(token);
  }

  init() {
    this.csrfToken$.subscribe((jwtToken) => {
      if (jwtToken) {
        localStorage.setItem(StorageKey.CSRF_TOKEN, jwtToken);
      }
    });

    this.fetchNewCsrfToken$ = this.getCsrfTokenRequest().pipe(
      tap({
        next: ({token}) => this.csrfToken$.next(token),
        error: () => this.redirectToLoginScreen(),
      }),
      share()
    );

    const token = this.getCsrfTokenFromUrl() || this.getCsrfTokenFromLocalStorage();

    if (token && this.isCsrfTokenValid(token)) {
      this.csrfToken$.next(token);
    } else {
      this.removeTokenFromLocalStorage();
    }
  }

  removeTokenFromLocalStorage() {
    localStorage.removeItem(StorageKey.CSRF_TOKEN);
  }

  onLogout() {
    this.removeTokenFromLocalStorage();
  }

  fetchNewCsrfToken() {
    if (!this.fetchNewCsrfTokenSub) {
      this.fetchNewCsrfToken$.subscribe();
    }
    return this.fetchNewCsrfToken$;
  }

  public getCsrfTokenRequest() {
    return this.httpClient.post<{ token: string }>(
      `${environment.auth.baseUrl}/auth/v1/saml/csrf`,
      {
        appId: environment.auth.appId,
      },
      {
        withCredentials: true,
      }
    );
  }

  private getCsrfTokenFromLocalStorage(): string | undefined {
    return localStorage.getItem(StorageKey.CSRF_TOKEN) || undefined;
  }

  private getCsrfTokenFromUrl(): string | undefined {
    const hash = window.location.hash;

    if (!hash || !hash.startsWith('#token=')) {
      return undefined
    }

    const value = new HttpParams({fromString: hash}).get('#token')!;
    window.location.hash = ''; // remove token form url
    return value;
  }

  private redirectToLoginScreen() {
    // navigate to login-url with current url as query-param
    window.location.href = `${environment.auth.baseUrl}/saml/sso/request?appId=${
      environment.auth.appId}&redirectUrl=${encodeURIComponent(window.location.href)}`;
  }

  private isCsrfTokenValid(token: string) {
    try {
      return !JwtUtil.isCSRFTokenExpired(token);
    } catch (_) {
      return false;
    }
  }
}
